import React, {useState} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row, Carousel, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Anime from "react-anime";
import Layout from "../layout";

import galleryData from "../images/gallery/gallery.json";
import GenericPage from "../components/generic-page";


const query = graphql`
query {
  allFile(filter: {
    relativePath: { glob: "./gallery/**/*.*" }
  }) {
    totalCount
    edges {
      node {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
}`;


function Gallery(props) {
  const {code} = props;

  const data = useStaticQuery(query);
  const files = data.allFile.edges
    .filter(({node}) => {
      // console.log("node", node);
      return node.relativePath.indexOf(`gallery/${code}`) > -1;
    })
    .sort((a, b) => {
      const a1 = parseFloat(a.node.relativePath.match(/^gallery\/.*\/(.*)\..*/)[1].replace(/[a-z]/, ".1"))
      const b1 = parseFloat(b.node.relativePath.match(/^gallery\/.*\/(.*)\..*/)[1].replace(/[a-z]/, ".1"));
      // console.log("asd", {a1, b1});
      return a1 - b1;
    });
  return (<Layout>
    <div className="snp-white-logo">
      <img src={"/logos/Sharon-Newman_logo_RGB_white.png"} style={{
        width: 450,
        opacity: 1
      }} />
    </div>
    <div id="gallery" className="gallery" style={{position: "relative"}}>
      <Container fluid className="no-gutters">
        {files.map(({node}) => {
          const image = getImage(node);
          return (<Row className="gallery-element" key={node.id}>
            <Col>
              <GatsbyImage image={image} alt="" loading={"eager"} style={{display: "block"}} />
            </Col>  
          </Row>);
        })}
      </Container>
    </div>
  </Layout>);
  
}


export default function PortfolioPage(props) {
  const {location: {search}} = props;
  const selectedCategory = new URLSearchParams(search).get("code");
  return (<Gallery code={selectedCategory} />)
  // const [selectedCategory, setSelectedCategory] = useState(undefined);


  // function handleCategoryClick(key) {
  //   return () => {
  //     setSelectedCategory(key);
  //   }
  // }

  // if(selectedCategory) {
  //   const gallery = galleryData[selectedCategory];
    
      
      {/* <div className="gallery-category-selector">
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {gallery.displayName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Object.keys(galleryData).filter((code) => code !== selectedCategory)
              .map((code) => {
                const gg = galleryData[code];
                return (<Dropdown.Item onClick={handleCategoryClick(code)}>{gg.displayName}</Dropdown.Item>);
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
  //   </>);
  // }
  // return (<GenericPage title={"Portfolio"}>
  //   <Container fluid>
  //     <Row>
  //       {Object.keys(galleryData).map((key) => {
  //         const gallery = galleryData[key]
  //         return (<Col xs={12} sm={6} key={key}>
  //           <div className="gallery-category" onClick={handleCategoryClick(key)}>
  //             <Container fluid>
  //               <Row className="align-items-center">
  //                 <Col xs={"auto"}>
  //                   <i className={gallery.icon}/>
  //                 </Col>
  //                 <Col xs={"auto"}>
  //                   {gallery.displayName}
  //                 </Col>
  //               </Row>
  //             </Container>
  //           </div>
  //         </Col>)
  //       })}
  //     </Row>
  //   </Container>

  // </GenericPage>)
}


// function GalleryItem(props) {
//   const {img, alt, background, ...rest} = props;
//   return (<Carousel.Item {...rest}>
//     <img
//       className="d-block w-100"
//       src={img}
//       alt={alt}
//     />
//   </Carousel.Item>);
// }
//   return (<div className="snp-gallery-bg fh" style={{backgroundImage: `url('${props.background}')`}}>
//     <Container fluid className="no-gutters fh-element fh">
//       <Row className="fh-element align-items-center justify-content-center">
//         <Anime className="col-auto snp-gallery-image " {...transition}>
//           <img src={props.img}/>
//         </Anime>
//       </Row>
//     </Container>
//   </div>);
// }



// export const imageQuery = graphql`
// query {
//   allFile(filter: {
//     relativePath: { glob: "./gallery/*.*" }
//   }) {
//     totalCount
//     edges {
//       node {
//         id
//         childImageSharp {
//           gatsbyImageData(
//             width: 1920
//             placeholder: BLURRED
//             formats: [AUTO, WEBP, AVIF]
//           )
//         }
//       }
//     }
//   }
// }`;

      // {/* <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundImage: `url("${currentItem.background}")`, backgroundSize: "cover", backgroundPosition:"center"}} /> */}


      // {/* <Container>
      //   <Row className={"vh-100 align-items-center"}>
      //     <Col sm={8} className="mx-auto">
      //       <div className="portfolio-selector-select">
      //         <Select options={categories.map((cat, i) => ({
      //           value: i, label: cat.name
      //         }))} onChange={({value}) => {
      //           setCategoryIndex(value)
      //         }} />
      //       </div>
      //       <div className="portfolio-header">
      //         {category.name}
      //       </div>
      //       <div key={`${categoryIndex}`}> 
      //         <Carousel activeIndex={index} onSelect={handleSelect}>
      //           {category.items.map((gi, indx) => <GalleryItem key={`${categoryIndex}${indx}`} {...gi} />)}
      //         </Carousel>
      //       </div>
      //     </Col>
      //   </Row>
      // </Container>
      // <div className="portfolio-selector-container" style={{position: "absolute", bottom: 15, left:0, right:0, zIndex: 99}}>
      //   <Container>
      //      <Row className="justify-content-center">
      //       {categories.map((cat, idx) => {
      //         return (<Col xs={"auto"} key={`${idx}`}>
      //           <div className="portfolio-selector" onClick={bindOnCategorySelect(idx)}>
      //             <img src={cat.img} />
      //             <div className="label">
      //               {cat.name}
      //             </div>
      //           </div>
      //         </Col>);
      //       })}
      //     </Row>

      //   </Container>
      // </div> */}